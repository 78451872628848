<template>
    <b-modal id="print-storage-location" size="lg" title="Print Storage Location" ref="modal" ok-title="Download"
        @ok="onDownload" :no-close-on-backdrop="true">

        <div class="text-center">
            <canvas id="canvas" :height="frameHeight" :width="frameWidth" />
        </div>

    </b-modal>
</template>

<script>
// Util
import { loadTextWithStyle, loadImages } from '@/utils/canvasUtil'

// QR Codes
import QRCode from 'qrcode';
import QrCodeBG from '../../../../public/img/qrcode/qrcode_frame_2.png';
import AyunLogo from '../../../../public/img/qrcode/default_logo.png';

// Others
import EventBus from '@/shared/event-bus';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'print-storage-location',
    props: {
        allCompaniesObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            frameHeight: 842,
            frameWidth: 595,

            qrCodeData: '',
            qrCodeDataUrl: '',

            selStorageLocation: null,
            currCompany: null
        };
    },
    mounted() {
        EventBus.$on('onPrintStorageLocation', (selStorageLocation) => {
            this.selStorageLocation = selStorageLocation;
            this.currCompany = this.allCompaniesObj[this.selStorageLocation.companyId];
            this.qrCodeData = this.currCompany.name + ' & ' + this.selStorageLocation.name;

            setTimeout(() => {
                this.draw();
            }, 500);
        });
    },
    methods: {
        async draw() {
            const canvas = document.getElementById('canvas');
            const context = canvas.getContext('2d');

            const qrcode = await QRCode.toDataURL(this.qrCodeData);
            const companyLogo = this.currCompany.companyLogo;

            let logoUrl = AyunLogo;
            if (companyLogo && companyLogo.url) {
                logoUrl = companyLogo.url;
            }

            const images = {
                img1: QrCodeBG,
                img2: qrcode,
                img4: logoUrl,
            };

            if (_.isEmpty(companyLogo) || !companyLogo.url) {
                images.img3 = AyunLogo;
            }

            loadImages(images, image => {
                // Main Background
                context.drawImage(image.img1, 0, 0, 595, 842);
                // QRCode
                context.drawImage(image.img2, 100, 270, 400, 400);

                if (image.img3) {
                    // AyunLogo
                    context.drawImage(image.img3, 355, 15, 200, 100);
                } else if (image.img4) {
                    // Company Logo
                    context.drawImage(image.img4, 400, 8, 100, 100);
                }

                // Company Name
                let companyName = this.currCompany.name.toUpperCase();
                loadTextWithStyle(
                    context,
                    companyName,
                    170,
                    250,
                    this.frameWidth,
                    40,
                    '#122b91',
                    "bold 30px Arial"
                );

                // Storage Location Name
                let storageName = this.selStorageLocation.name;
                loadTextWithStyle(
                    context,
                    storageName,
                    195,
                    287,
                    this.frameWidth,
                    40,
                    '#008036',
                    "bold 23px Arial"
                );

                context.restore();

                this.qrCodeDataUrl = canvas.toDataURL('image/png');
            });
        },

        async onDownload(evt) {
            evt.preventDefault();

            const url = this.qrCodeDataUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${_.toLower(this.qrCodeData)}.png`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

        },
    },
    beforeDestroy() {
        EventBus.$off('onPrintStorageLocation');
    },
};
</script>